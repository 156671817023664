<div class="event-item" (click)="toEvento()">
      <div class="capa">
          <figure>  
              <img *ngIf="data.foto != null" [src]="data.foto" [alt]="data.nome" width="100%" />
              <img *ngIf="data.foto == null" src="/images/sem-foto/capa.jpg" [alt]="data.nome" width="100%" />
          </figure> 
      </div>
      <div class="content">
          <h3>{{data.nome}}</h3>
          <h4>{{data | eventoDateFormat}}</h4>
          <div class="footer">
              <div class="box">
                  <a href="/eventos/{{data.apelido}}" title="Compre Aqui" class="btn-more">
                      <span>SAIBA MAIS</span>
                  </a>
              </div>
          </div>
      </div>
  </div>
  