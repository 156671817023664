import { Injectable } from '@angular/core';

@Injectable()
export class DateService {

  constructor() { }

  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonthStringTwo(month){

    let out   = "";

    switch(month){
      case 1:
        out = "Janeiro";
      break;
      case 2:
        out =  "Fevereiro";
      break;
      case 3:
        out =  "Março";
      break;
      case 4:
        out =  "Abril";
      break;
      case 5:
        out =  "Maio";
      break;
      case 6:
        out =  "junho";
      break;
      case 7:
        out =  "julho";
      break;
      case 8:
        out =  "Agosto";
      break;
      case 9:
        out =  "Setembro";
      break;
      case 10:
        out =  "Outubro";
      break;
      case 11:
        out =  "Novembro";
      break;
      case 12:
        out =  "Dezembro";
      break;
    }

    return out;

  }
  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonthString(value){

    let data  = new Date(value);
    let month = data.getMonth()+1; 
   
    let out   = "";

    switch(month){
      case 1:
        out = "Janeiro";
      break;
      case 2:
        out =  "Fevereiro";
      break;
      case 3:
        out =  "Março";
      break;
      case 4:
        out =  "Abril";
      break;
      case 5:
        out =  "Maio";
      break;
      case 6:
        out =  "junho";
      break;
      case 7:
        out =  "julho";
      break;
      case 8:
        out =  "Agosto";
      break;
      case 9:
        out =  "Setembro";
      break;
      case 10:
        out =  "Outubro";
      break;
      case 11:
        out =  "Novembro";
      break;
      case 12:
        out =  "Dezembro";
      break;
    }

    return out;

  }
  /**
   * 
   * Retorna o dia da data
   * 
   */
  getDay(value){

    let data = new Date(value);

    return data.getUTCDate() < 10 ? "0"+data.getUTCDate() : data.getUTCDate();

  }
  /**
   * 
   * Retorna o ano da data
   * 
   */
  getYear(value){
    
    let data = new Date(value);
        
    return data.getFullYear();
    
  }
  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonth(value){  
    
    let data  = new Date(value);
    let month = data.getUTCMonth()+1;

    let out   = month < 10 ? "0"+month : month;
    
    return out;
    
  }
  /**
   * 
   * Format date
   * 
   */
  formatDate(d,separator="/",format="pt-BR"){

    var date  = new Date(d); 
    let dia:any   = date.getUTCDate();
    dia = dia < 10 ? "0"+dia : dia; 
    let mes:any = date.getUTCMonth()+1; 
    mes = mes < 10 ? "0"+mes : mes;  
    let ano  = date.getUTCFullYear(); 
    
    if(format == "pt-BR"){
      return dia+separator+mes+separator+ano;
    }else{
      return ano+separator+mes+separator+dia;
    }  

  }
  /**
   * 
   * Get date today
   *  
   */
  today(separator="-"){

    let date = new Date();

    let day       = date.getUTCDate();
    let month:any = date.getUTCMonth();
    month = month+1;
    if(month  < 10){
      month = "0"+month;
    }
    let year  = date.getUTCFullYear();
    
    return day+separator+month+separator+year;

  }
  /**
   * 
   * Formata a data
   * 
   * @param data 
   * 
   */
  formatDateFromDatepicker(data,locale='pt-BR'){

    let date = new Date(data);

    let dia     = date.getDate();
    let mes:any = date.getUTCMonth()+1;
    mes         = mes < 10 ? "0"+mes : mes;
    let ano     = date.getFullYear();

    return locale != 'pt-BR' ? ano+"-"+mes+"-"+dia : dia+"-"+mes+"-"+ano;

  }
  /***
   * 
   * Adiciona dias a uma data
   * 
   */
  addDaysMask(d,days){
    
    let data = d.split("/");
    let dia  = data[0] < 10 ? "0"+data[0] : data[0]; 
    let mes  = data[1] < 10 ? "0"+data[1] : data[1]; 
    let ano  = data[2]; 
    var date:any = new Date(ano+"-"+mes+"-"+dia);
    date.setDate(date.getDate()+days);
    return date;

  }
  /***
   * 
   * Adiciona dias a uma data
   * 
   */
  subDaysMask(d,days):any{
    
    if(d != null){

      let data = d.split("/");
      let dia  = data[0] < 10 ? "0"+data[0] : data[0]; 
      let mes  = data[1] < 10 ? "0"+data[1] : data[1]; 
      let ano  = data[2]; 
      var date:any = new Date(ano+"-"+mes+"-"+dia);
      date.setDate(date.getDate()-days);
      
      return date;

    }

  }
  /**
  * 
  * Retorna a diferença de dias entre duas datas
  * 
  **/
  diferencaDias(dt1,dt2){

    let date1    = new Date(dt1);
    let date2    = new Date(dt2);
    let diffTime = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;

  }

}
