<div class="menu-partial {{classCustom}} template-{{template}}" (window:resize)="onResize()">
      <button type="button" class="btn-open" (click)="_open()">
          <img src="/images/icons/menu.svg" alt="Menu" width="50px" />
      </button>  
      <div class="close" #close (click)="_open()">  
          <img src="/images/icons/close.svg" width="40px" height="40px" alt="Close" />
      </div>    
      <div class="content" #content> 
          <div class="header-logo">
              <img src="/images/logos/logo-onne.svg" width="100px" alt="Logo" />
          </div>
          <ul>
              <li>
                  <a routerLink="/" title="Onne - Inteligência em Eventos">
                      <div class="icon">
                          <img src="/images/icons/home.svg" alt="Sobre a Gramado Receptivo" height="20px" />
                      </div>
                      <span>Home</span>
                  </a>    
              </li>
              <li>
                  <a routerLink="/quem-somos" title="Sobre a Onne">
                      <div class="icon">
                          <img src="/images/icons/sobre.svg" alt="Sobre a Onne" height="20px" />
                      </div>
                      <span>Quem Somos</span>
                  </a>    
              </li>
              <li>
                  <a routerLink="/eventos" title="Nossos Eventos">
                      <div class="icon">
                          <img src="/images/icons/evento.svg" alt="Nossos Eventos" height="20px" />
                      </div>
                      <span>Eventos</span>
                  </a>    
              </li>
              <li>
                  <a routerLink="/contato" title="Contato">
                      <div class="icon">
                          <img src="/images/icons/contato-01.svg" alt="Contato" height="20px" />
                      </div>
                      <span>Contato</span>
                  </a>    
              </li>
          </ul>
      </div>   
  </div> 
  <div class="overlay" #overlay></div>   
  