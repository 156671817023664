import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'evento-item',
  templateUrl: './evento-item.component.html',
  styleUrls: ['./evento-item.component.scss']
})
export class EventoItemComponent implements OnInit {

  @Input("data") data:any = {}; 

  constructor(
    private router: Router
  ){}

  /**
   * 
   * To evento
   * 
   */
  toEvento(){

    this.router.navigateByUrl("/eventos/"+this.data.apelido);
    return false;
    
  }
  /**
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}

}
