<section class="page-home">  
  <modulo posicao="banner-slider" class="wow fadeIn"></modulo>
  <modulo posicao="gatilho-mental"></modulo>
  <modulo posicao="eventos"></modulo>
  <modulo posicao="somos-especialistas"></modulo>
  <modulo posicao="sobre"></modulo>
  <modulo posicao="cliente"></modulo>
  <modulo posicao="newsletter"></modulo>   
</section>   

 



