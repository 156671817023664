import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventoItemComponent } from 'src/app/components/evento/evento-item/evento-item.component';
import { PartialModule } from '../../partial/partial.module';
import { PipeModule } from '../../pipe/pipe.module';
import { LoaderModule } from '../../loader/loader/loader.module';

@NgModule({
  declarations: [
    EventoItemComponent
  ],
  exports: [
    EventoItemComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    PipeModule,
    LoaderModule
  ]
})
export class EventoItemModule{}
