<div class="case-item">
    <div class="capa" (click)="openLink()">
        <figure>  
            <img [src]="data.foto" [alt]="data.nome" width="100%" height="auto" />
        </figure>
    </div>
    <div class="content">
        <div class="item">
            <div class="icon">
                <i class="fa-solid fa-calendar-days"></i>
            </div>
            <div class="text">
                <span>{{data.ano}}</span>
            </div>
        </div>
        <div class="item">
            <div class="icon">
                <i class="fa-solid fa-location-dot"></i>
            </div>
            <div class="text">
                <span>{{data.local}}</span>
            </div>
        </div>
        <div class="item">
            <div class="icon">
                <i class="fa-solid fa-person"></i>
            </div>
            <div class="text">
                <span>{{data.participantes}} participantes</span>
            </div>
        </div>
    </div>
</div>
