<footer>   
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-item">
                    <figure>
                        <img src="/images/logos/logo-branco.svg" alt="Onne - Inteligência em Eventos" width="200px" />
                    </figure>
                </div>
                <div class="col-md-3 col-item">
                    <h3 class="title-h3">{{'Links Úteis' | translate}}</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/contato" title="Passeios">
                                {{'Contato' | translate}}
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/quem-somos" title="Quem Somos">
                                {{'Quem Somos' | translate}}
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/faq" title="Perguntas Frequentes" (click)="toRouter('/faq')">
                                {{'Perguntas Frequentes' | translate}}
                            </a> 
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-item col-address">
                    <h3 class="title-h3">{{'Onde Estamos' | translate}}</h3>
                    <modulo posicao="endereco" template="footer"></modulo>
                </div>
                <div class="col-md-3 col-item">
                    <h3 class="title-h3">{{'Contatos' | translate}}</h3>
                    <modulo posicao="contato" template="footer"></modulo>
                    <modulo posicao="rede-social" template="footer"></modulo>
                </div>
            </div>
        </div>  
    </div>    
    <div class="bottom">
        <div class="container">
            <div class="copyright">
                <p>
                    <span>Copyright © 2024</span>
                    <a href="https://digitalmovement.com.br/" target="_blank" title="Digital Movement - Soluções em Tecnologia">
                        <strong>Digital Movement</strong>
                        <img src="images/logos/logo-dm.svg" alt="Digital Movement" width="20px" height="20px" />
                    </a>
                </p>
            </div> 
        </div>  
    </div>    
</footer>  