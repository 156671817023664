<div class="module module-about">
      <div class="module-content">
          <div class="container">
              <div class="row">
                  <div class="col-md-5 center-flex">
                      <div class="text">
                          <h2 class="wow slideInUp">{{data.titulo}}</h2>
                          <figure class="wow slideInLeft">
                              <img src="/images/logos/logo-onne.svg" alt="Onne" width="280px" />
                          </figure>
                      </div>
                  </div>
                  <div class="col-md-7">
                        <div class="text-about">
                          <h3 class="wow slideInUp">{{data.subtitulo}}</h3>
                          <article class="wow slideInDown" [innerHtml]="data.params.texto"></article>
                          <div class="content-button">
                              <button type="button" class="btn btn-one wow slideInUp" (click)="toRoute()">
                                  <div class="icon">
                                      <img src="/images/logos/logo-simbolo.svg" alt="Onne" width="15px" />
                                  </div>
                                  <span>SAIBA MAIS</span>
                              </button>
                          </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  