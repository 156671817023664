<header class="header template-{{template}}" #header (window:resize)="onResize()">
    <div class="content">
        <div class="container">
            <div class="content-logo center-flex">  
                <modulo [template]="template" class="content-logo" posicao="logo"></modulo>
                <lg-list [template]="template"></lg-list>
            </div>
            <div class="content-menu">
                <menu-partial [template]="template" classCustom="menu-home"></menu-partial>
            </div>
        </div>     
    </div>  
</header>   