import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventosComponent } from 'src/app/modulos/eventos/eventos.component';
import { PartialModule } from '../../partial/partial.module';
import { DirectivesModule } from '../../directives/directives/directives.module';
import { PipeModule } from '../../pipe/pipe.module';
import { EventoItemModule } from '../../evento/evento-item/evento-item.module';

@NgModule({
  declarations: [
    EventosComponent
  ],
  exports: [
    EventosComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    DirectivesModule,
    PipeModule,
    EventoItemModule
  ]
})
export class ModuloEventosModule { }
