<div [ngSwitch]="template">
  <section *ngSwitchCase="'home'" class="modulo modulo-contato-home default-home" itemscope itemtype="https://schema.org/Organization">
    <div class="row">
      <div class="col-md-4">
        <h3>{{'Nosso E-mail' | translate}}</h3>
        <div class="contato-item" (click)="openEmail()">
          <div class="icon bg-blue">
            <i class="fa-solid fa-envelope"></i>
          </div>   
          <div class="content"> 
            <span>{{data.params.email}}</span>
          </div> 
        </div>
      </div>
      <div class="col-md-4"> 
        <h3>{{'Fale por WhatsApp' | translate}}</h3>
        <div class="contato-item" (click)="openWhatsApp()">
          <div class="icon bg-blue">
            <i class="fa-brands fa-whatsapp"></i>
          </div>   
          <div class="content">
            <span>{{data.params.whatsapp}}</span>
          </div>
        </div> 
      </div>
      <div class="col-md-4">
        <h3>{{'Envie uma Mensagem' | translate}}</h3>
        <div class="contato-item" (click)="toContato()">
          <div class="icon bg-blue">
            <i class="fa-solid fa-message"></i>
          </div>   
          <div class="content">
            <span>{{'Clique Aqui' | translate}}</span>
          </div> 
        </div>
      </div>  
    </div>  
  </section>  
  <section *ngSwitchCase="'footer'" class="template-fale-conosco">
    <ul class="menu-list">
      <li>
          <div class="item">
              <div class="icon">
                  <i class="fa-brands fa-whatsapp"></i>
              </div>
              <div class="text">
                  +55 {{data.params.whatsapp}}
              </div>
          </div>
      </li>
      <li>
          <div class="item">
              <div class="icon">
                  <i class="fa-solid fa-envelope"></i>
              </div>
              <div class="text">
                  {{data.params.email}}
              </div>
          </div>
      </li>
  </ul>
  </section>
  <section class="section section-contact" *ngSwitchCase="'page-contato'">
    <div class="container">
        <div class="row">
            <div class="col-md-4"> 
                <div class="box-sombra card-item-contact wow slideInUp" data-wow-delay="0.4s" (click)="openWhatsApp()">
                    <div class="card-item-header">
                        <div class="icon">
                            <i class="fa-brands fa-whatsapp"></i>
                            <span>{{'WhatsApp' | translate}}</span>
                        </div> 
                        <div class="text">
                            <h3>{{data.params.whatsapp}}</h3>  
                        </div>  
                    </div>
                </div>  
            </div> 
            <div class="col-md-4"> 
                <div class="box-sombra card-item-contact wow slideInDown" data-wow-delay="0.5s" (click)="openEmail()">
                    <div class="card-item-header">
                        <div class="icon">
                            <i class="fa-solid fa-envelope"></i>
                            <span>{{'E-mail' | translate}}</span>
                        </div>
                        <div class="text">
                            <h3>{{data.params.email}}</h3>  
                        </div>  
                    </div>
                </div>
            </div> 
            <div class="col-md-4"> 
                <div class="box-sombra card-item-contact wow slideInUp" data-wow-delay="0.4s" (click)="openTel()">
                    <div class="card-item-header">
                        <div class="icon">
                            <i class="fa-solid fa-square-phone"></i>
                            <span>{{'Telefone' | translate}}</span>
                        </div> 
                        <div class="text">
                            <h3>{{data.params.telefone}}</h3>  
                        </div>  
                    </div>
                </div>  
            </div>   
        </div>    
    </div>
  </section>
</div>  

