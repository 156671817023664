import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'lg-list',
  templateUrl: './lg-list.component.html',
  styleUrls: ['./lg-list.component.scss']
})
export class LgListComponent implements OnInit {
  
  @Input("template") template:any = 1;
  public list:any = [];
  public show     = false;
  public loader   = false;
  public codigo   = "pt-BR";

  constructor(
    private data: DataService,
    private app: AppService,
    private api: ApiService
  ){}

  /**
   * 
   * Show
   * 
   */
  _show(){

    this.show = this.show ? false : true;

  }
  /**
   * 
   * Set linguagem
   * 
   */
  setList(){

    this.list = this.data.getLinguagens();

  }
  /**
   * 
   * Select
   * 
   */
  _select(lg){

    try{

      this.loader = true;

      this.api.traducao().linguagem(lg).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.data.setLinguagem(lg.codigo);  
          this.codigo = lg.codigo;
          this._show();
          document.location.reload();

        }else{

          let error = this.api.formatError(response);
          this.app.info(error.message);

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.app.info(error.message);

      });

    }catch(e){

      this.loader = false;
      this.app.info("Houve algo inesperado: "+e.messgae);

    }

  }
  /**
   * 
   * Set codigo
   * 
   */
  setCodigo(){

    this.codigo = this.data.getLinguagem();

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setList();
    this.setCodigo();  
  }

}
