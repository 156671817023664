import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

declare var $:any;

@Component({
  selector: 'modulo-eventos-two',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {

  @ViewChild("slider") slider: any;
  @Input("data") data; 
  @Input("template") template       = null; 
  @Input("classCustom") classCustom = "";
  @Input("endereco") endereco;   
  public mobile:any = false;

  constructor(
    private app: AppService
  ){ }

  /***
   * 
   * Inicializa o Slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 4,
      dots: false, 
      arrows: false,
      centerMode: false,
      autoplay: false,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Init
   * 
   */
  onResize(){

    this.mobile = this.app.isMobile();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }
}
