<div class="module modulo-cliente">
      <div class="module-title" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <div class="content-title">
                        <div class="box">
                              <h3>{{data.titulo}}</h3>
                        </div>
                  </div>
            </div>
      </div> 
      <div class="modulo-content">
            <div class="container">
                  <div class="slider-itens" #slider>
                        <div class="slider-item" *ngFor="let d of data.clientes">
                            <cliente-item [data]="d"></cliente-item>   
                        </div>
                  </div> 
                  <div class="slide-navigate-item prev" (click)="prev()"> 
                        <img width="50px" height="30px" src="/images/icons/prev-03.svg" alt="Prev" />
                  </div>
                  <div class="slide-navigate-item next" (click)="next()">
                        <img width="50px" height="30px" src="/images/icons/next-03.svg" alt="Next" />
                  </div>
            </div>      
      </div>      
</div>      