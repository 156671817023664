import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'partial-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public listCard:any     = null;
  public listPix:any      = null;
  public certificados:any = [];

  constructor(
    private app:AppService,
    private router: Router
  ){}

  /**
   * 
   * Open email
   * 
   */
  openEmail(){

    window.open("mailto:contato@tourinsta.com.br");

  }
  /**
   * 
   * Open
   * 
   */
  openWhatsApp(){
    
    let prefixo = this.app.isMobile() ? "api" : "web";

    window.open(`https://${prefixo}.whatsapp.com/send?phone=5561974008078`,"_blank");

  }
  /**
   * 
   * To Up
   * 
   */
  toUp(){

    window.scrollTo({top: 0,behavior:'smooth'});

  }
  /**
   * 
   * Set list payment
   * 
   */
  setListPayment(){

    this.listCard = [{
      title: "Visa",
      img: "images/payment/visa.png"
    },{
      title: "Maestro",
      img: "images/payment/maestro.png"
    },{
      title: "JCB",
      img: "images/payment/jcb.png"
    },{
      title: "American Express",
      img: "images/payment/american-express.png"
    },{
      title: "Paypal",
      img: "images/payment/paypal.png"
    }];

    this.listPix = [{
      title: "Pix",
      img: "images/payment/pix.png"
    },{
      title: "Boleto",
      img: "images/payment/boleto.png"
    }];

  }
  /**
   * 
   * Set Data
   * 
   */
  setData(){

    this.certificados = [{
      title: "DF Trans",
      image: "images/certificados/01.jpg"
    },{
      title: "ANTT",
      image: "images/certificados/02.jpg"
    },{
      title: "Cadastur",
      image: "images/certificados/03.jpg"
    },{
      title: "Carro Blindado",
      image: "images/certificados/04.jpg"
    }];

  }
  /**
   * 
   * To router
   * 
   */
  toRouter(url){

    this.router.navigateByUrl(url);
    return false;

  }
  /**
   * 
   * To contato
   * 
   **/
  toContato(){

    this.router.navigateByUrl("/contato"); 

  }
  /**
   * 
   * To up
   * 
   */
  ngOnInit():void{
    this.setListPayment();
    this.setData();
  }

}
