import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'modulo-gatilho-mental',
  templateUrl: './gatilho-mental.component.html',
  styleUrls: ['./gatilho-mental.component.scss']
})
export class GatilhoMentalComponent implements OnInit {
  
  @Input("data") data;
  @Input("template") template; 
  @Input("classCustom") classCustom; 
  public dataItem:any = {};
  public loader       = false;   
  public message = { 
    data: "",
    class: "",
    show: false 
  };
  public subscribeMessage = null;
  public messageLoader = "Enviando. Aguarde!"; 

  constructor(
    private app: AppService,
    private api: ApiService
  ){}

  /**
   * 
   * Init
   * 
   * 
   */
  ngOnInit():void{}

}
