<div class="module module-events" (window:resize)="onResize()">
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <h2 class="wow slideInUp">{{data.titulo}}</h2>
          </div>
      </div>
      <div class="module-content" *ngIf="!mobile">
          <div class="container"> 
              <div class="row">
                  <div class="col-md-4" *ngFor="let p of data.eventos">
                      <evento-item [data]="p"></evento-item>
                  </div> 
              </div>
          </div>
      </div>
      <div class="module-content" *ngIf="mobile">
          <div class="container"> 
              <div class="wp-layout-grid" #slider>  
                  <div class="wp-layout-item" *ngFor="let p of data.eventos">
                      <evento-item [data]="p"></evento-item>
                  </div>
              </div>
          </div>
      </div> 
</div>
  