import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'modulo-somos-especialistas',
  templateUrl: './somos-especialistas.component.html',
  styleUrls: ['./somos-especialistas.component.scss']
})
export class SomosEspecialistasComponent implements OnInit {

  @Input("data") data;
  @Input("template") template; 
  @Input("classCustom") classCustom; 
  public dataItem:any = {};
  public loader       = false;   
  public message = { 
    data: "",
    class: "",
    show: false 
  };
  public subscribeMessage = null;
  public messageLoader = "Enviando. Aguarde!"; 

  constructor(
    private app: AppService,
    private api: ApiService
  ){}

  /**
   * 
   * Init
   * 
   * 
   */
  ngOnInit():void{}

}
