<div class="module-mental-trigger">
      <div class="container">
          <ul class="list">  
              <li *ngFor="let i of data.items">
                  <div class="item wow slideInUp">
                      <div class="icon">
                          <img [src]="i.icone" [alt]="i.titulo" height="50px" />
                      </div>
                      <h3>{{i.titulo}}</h3>
                      <article [innerHtml]="i.texto"></article>
                  </div>
              </li>
          </ul>
      </div>
  </div>
  