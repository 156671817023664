<div class="module-specialty">
      <div class="module-content">
          <div class="container">
              <div class="row">
                  <div class="col-md-6 center-flex">
                      <div class="text" *ngIf="data.mostrar_titulo">
                          <h2 class="wow slideInUp">{{data.titulo}}</h2>
                          <h3 class="wow slideInLeft" *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
                      </div>
                  </div>
                  <div class="col-md-6 border">
                      <ul class="list-items">
                          <li class="wow slideInUp" *ngFor="let d of data.items">
                              <div class="icon">
                                  <img [src]="d.icone" [alt]="d.titulo" width="30px" />
                              </div>
                              <span>{{d.titulo}}</span>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>
  