import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeModule } from './modules/home/home.module';

const routes: Routes = [{ 
  path: '',     
  loadChildren: () => HomeModule 
},{
  path: 'quem-somos',    
  loadChildren: () => import('src/app/modules/sobre/sobre-module/sobre.module').then(m => m.SobreModule) 
},{
  path: 'eventos/:apelido',   
  loadChildren: () => import('src/app/modules/pages/eventos/evento-page/evento-page.module').then(m => m.EventoPageModule) 
},{
  path: 'eventos',  
  loadChildren: () => import('src/app/modules/pages/eventos/eventos-page/eventos-page.module').then(m => m.EventosPageModule) 
},{
  path: 'eventos/pagamento/:id',   
  loadChildren: () => import('src/app/modules/pages/eventos/evento-pagamento-page/evento-pagamento-page.module').then(m => m.EventoPagamentoPageModule) 
},{    
  path: 'eventos/pagamento/confirmado/:id',   
  loadChildren: () => import('src/app/modules/pages/eventos/evento-pagamento-confirmado-page/evento-pagamento-confirmado-page.module').then(m => m.EventoPagamentoConfirmadoPageModule) 
},{
  path: 'politicas-de-privacidade',
  loadChildren: () => import('src/app/modules/politica/politica-privacidade/politica-privacidade.module').then(m => m.PoliticaPrivacidadeModule)
},{
  path: 'termos-e-condicoes', 
  loadChildren: () => import('src/app/modules/pages/termos-e-condicoes-page/termos-e-condicoes-page.module').then(m => m.TermosECondicoesPageModule)
},{
  path: 'contato',  
  loadChildren: () => import('src/app/modules/contato/contato.module').then(m => m.ContatoModule) 
},{    
  path: '**',
  pathMatch: 'full', 
  redirectTo: ''
}];
    
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
