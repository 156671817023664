<div class="module-newsletter">
    <div class="module-title">
        <div class="container">
            <h4>{{data.titulo}}</h4>
            <h5>{{data.subtitulo}}</h5>
        </div>
    </div>
    <div class="content">
        <div class="container">
            <form class="form-custom" (submit)="send()">
                <div class="content-input">    
                    <div class="item-input" data-wow-delay="0.3s">
                        <div class="icon">
                            <img src="/images/icons/user-email-01.svg" alt="User Name" width="20px" />
                        </div>
                        <div class="input">
                            <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="DIGITE O SEU E-MAIL" />    
                            <button type="button">
                                <span><i class="fa-solid fa-magnifying-glass"></i></span>
                            </button>
                        </div>
                    </div> 
                </div>   
            </form>   
        </div>
    </div>
</div>    
<loader *ngIf="loader"></loader>
