<div class="module-whatsapp fixed" (click)="open()" *ngIf="template == 'fixo'"> 
    <div class="content">
        <div class="header">
            <div class="icon-left">
                <i class="fa-brands fa-whatsapp"></i>
            </div>    
            <span>{{'Fale com a nossa equipe?' | translate}}</span> 
        </div>
    </div>   
</div>   
<div class="module-whatsapp" (click)="open()" *ngIf="template == 'pagamento'"> 
    <div class="content">
        <div class="header">
            <div class="icon-left">
                <i class="fa-brands fa-whatsapp"></i>
            </div>      
            <span>{{'Entrar em contato com o suporte' | translate}}</span> 
        </div>
    </div>   
</div>     