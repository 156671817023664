import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modulo-sobre', 
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  @Input("data") data:any           = {};
  @Input("classCustom") classCustom = "";
  @Input("template") template       = ""; 

  constructor(
    private router: Router
  ){}  

  /**
   * 
   * To about
   * 
   */
  toRoute(){

    this.router.navigateByUrl("/quem-somos");

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
