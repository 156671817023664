<a routerlink="/" *ngIf="template == 1" (click)="_click()">
  <figure class="logo {{classCustom}}"> 
    <img width="170px" [src]="logo" alt="Logo" />
  </figure>
</a>   
<a routerlink="/" *ngIf="template == 2" (click)="_click()">
  <figure class="logo {{classCustom}}"> 
    <img width="170px" [src]="logo" alt="Logo" />
  </figure>
</a> 
<a class="default-footer" routerLink="/" *ngIf="template == 'footer'" (click)="_click()">
  <figure class="logo {{classCustom}}"> 
    <img width="170px" [lazyLoad]="logo" alt="Logo" defaultImage="/images/pixel.png" />
  </figure>
</a> 
  